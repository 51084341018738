<template>
  <div id="UserManagementList">
    <app-title-content
      class="mb-4"
      :items="breadcrumbs"
      name="UserList"
    ></app-title-content>

    <!-- <div class="row mb-3">
      <div class="col">
        <input
          type="text"
          class="form-control input-custom-search"
          placeholder="ชื่อ - นามสกุล"
        />
      </div>
      <div class="col">
        <select2
          class="select-custom-search"
          :options="departmentList"
          v-model="selectedDept"
          placeholder="หน่วยงาน"
          :settings="{ allowClear: true }"
        >
        </select2>
      </div>
      <div class="col-1">
        <button type="button" class="btn btn-search w-100">ค้นหา</button>
      </div>
    </div> -->

    <div class="my-4 box-shadow p-5">
      <div class="d-flex mb-3 align-items-center">
        <div class="d-flex flex-fill align-items-baseline">
          <span class="weight-700 ml-1 fontsize-h5">รายการผู้ใช้งาน</span>
          <span class="weight-700 fontsize-h5 ml-2"
            >จำนวน {{ total | toCommas }} รายการ</span
          >
        </div>
        <div class="">
          <button type="button" class="btn btn-submit" @click="addNewUser">
            <font-awesome-icon
              :icon="['fas', 'plus-circle']"
              class="font-white mr-2"
            />
            เพิ่มผู้ใช้งาน
          </button>
        </div>
      </div>
      <b-table
        id="my-table-user"
        class="table-custom box-shadow"
        hover
        borderless
        show-empty
        :items="items"
        :fields="fields"
        :busy.sync="isBusy"
      >
        <template v-slot:empty="">
          <div class="text-center fontsize-h4 my-4">ไม่พบข้อมูล</div>
        </template>

        <div slot="table-busy" class="text-center font-blueinfo my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="pl-2">กำลังโหลด...</strong>
        </div>

        <template v-slot:head()="data">
          <div
            :class="
              data.field.align === 'left'
                ? 'text-left'
                : data.field.align === 'right'
                ? 'text-right'
                : 'text-center'
            "
          >
            {{ data.label }}
          </div>
        </template>

        <template v-slot:head(index)="data">
          <div class="text-center">
            {{ data.label }}
          </div>
        </template>

        <!-- Row running number -->
        <template v-slot:cell(index)="data">
          <div class="text-center">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </div>
        </template>

        <!-- Row Custom align -->
        <template v-slot:cell()="data">
          <div
            v-if="data.item.alignRow !== undefined"
            :class="
              data.item.alignRow === 'left'
                ? 'text-left'
                : data.item.alignRow === 'right'
                ? 'text-right'
                : 'text-center'
            "
          >
            {{ data.value }}
          </div>
          <div
            v-else
            :class="
              data.field.align === 'left'
                ? 'text-left'
                : data.field.align === 'right'
                ? 'text-right'
                : 'text-center'
            "
          >
            {{ data.value }}
          </div>
        </template>

        <template v-slot:cell(canEdit)="data">
          <div class="text-center">
            <button
              type="button"
              class="btn btn-edit"
              :disabled="!data.item.canEdit"
              @click="editRow(data.item.userID)"
            >
              <font-awesome-icon
                :icon="['fas', 'edit']"
                class="color-icon-edit fa-lg"
              />
            </button>
          </div>

          <!-- <div v-if="data.item.canEdit" class="text-center">
            <font-awesome-icon
              :icon="['fas', 'edit']"
              @click="editRow(data.item.userId)"
              class="color-icon-edit cursor-pointer"
            />
          </div>
          <div v-else class="text-center">
            <font-awesome-icon
              :icon="['fas', 'edit']"
              class="color-icon-edit"
              disabled
            />
          </div> -->
        </template>

        <template v-slot:cell(canDelete)="data">
          <div class="text-center">
            <button
              type="button"
              class="btn btn-trash"
              :disabled="!data.item.canDelete"
              @click="deleteRow(data.item.userID)"
            >
              <font-awesome-icon
                :icon="['fas', 'trash-alt']"
                class="color-icon-trash fa-lg"
              />
            </button>
          </div>

          <!-- <div v-if="data.item.canDelete" class="text-center">
            
          </div>
          <div v-else class="text-center">
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              class="color-icon-trash"
              disabled
            />
          </div> -->
        </template>
      </b-table>

      <b-pagination
        class="pagination-custom mt-3"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        align="right"
        aria-controls="my-table-user"
        @change="changePage($event)"
      ></b-pagination>
    </div>

    <div class="d-flex justify-content-center">
      <button
        type="submit"
        class="btn btn-outline-submit"
        @click="gotoMenuPage()"
      >
        <iconify icon="fa-solid:home" class="fontsize-h4 mr-2" />
        <span class="align-middle">กลับไปยังหน้ารายการ</span>
      </button>
    </div>
  </div>
</template>

<script>
import { UserService } from "@/services/main.service";

export default {
  name: "usermanagement-list",
  data() {
    const breadcrumbs = [
      {
        to: "UserList",
        text: "จัดการผู้ใช้งาน",
      },
    ];
    return {
      breadcrumbs: breadcrumbs,
      perPage: 50,
      currentPage: 1,
      items: [],
      fields: [
        { key: "index", label: "ลำดับ" },
        { key: "userFullName", label: "ชื่อ - นามสกุล", align: "left" },
        { key: "userName", label: "Username", align: "left" },
        { key: "departmentName", label: "หน่วยงาน", align: "left" },
        { key: "canEdit", label: "แก้ไข" },
        { key: "canDelete", label: "ลบ" },
      ],
      total: 0,
      isBusy: false,
      userReporter: process.env.VUE_APP_REPORTER_USER,
    };
  },
  async created() {
    await this.getListUser();
  },
  methods: {
    async changePage(event) {
      this.currentPage = event;
      await this.getListUser();
    },
    addNewUser() {
      this.$router.push({
        name: "UserFormAdd",
        params: {
          mode: "add",
        },
      });
    },
    editRow(userId) {
      this.$router.push({
        name: "UserFormEdit",
        params: {
          mode: "edit",
          userId: userId,
        },
      });
    },
    async getListUser() {
      this.isBusy = true;
      const param = {
        NumPage: this.currentPage,
        NumRow: this.perPage,
      };
      await UserService.getListUser(param)
        .then((res) => {
          this.items = res.data.map((m) => {
            const _check = m.userName !== this.userReporter;
            return {
              ...m,
              canEdit: _check,
              canDelete: _check,
            };
          });

          this.total = res.total;
        })
        .catch((err) => {
          this.items = [];
          this.total = 0;
          this.currentPage = 1;
        })
        .finally((f) => {
          this.isBusy = false;
        });
    },
    async deleteRow(userId) {
      const isLastRowInLastPage = this.checkLastPage();

      this.alertDeleteForm().then(async (result) => {
        if (result) {
          await UserService.postDeleteUser(userId)
            .then((res) => {
              if (res && res?.status) {
                this.alertSuccess("ลบรายการสำเร็จ");
                if (isLastRowInLastPage) this.currentPage--;

                this.getListUser();
              } else {
                this.alertFail("ลบรายการไม่สำเร็จ");
              }
            })
            .catch((err) => {
              this.alertFail("ลบรายการไม่สำเร็จ");
            });
        }
      });
    },
    checkLastPage() {
      const total = this.total;
      const perPage = this.perPage;
      const currentPage = this.currentPage;
      const isLastRowInLastPage = total === perPage * (currentPage - 1) + 1;
      return isLastRowInLastPage;
    },
    gotoMenuPage() {
      this.$router.push({ name: "MenuPage" });
    },
  },
};
</script>

<style></style>
