var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"UserManagementList"}},[_c('app-title-content',{staticClass:"mb-4",attrs:{"items":_vm.breadcrumbs,"name":"UserList"}}),_c('div',{staticClass:"my-4 box-shadow p-5"},[_c('div',{staticClass:"d-flex mb-3 align-items-center"},[_c('div',{staticClass:"d-flex flex-fill align-items-baseline"},[_c('span',{staticClass:"weight-700 ml-1 fontsize-h5"},[_vm._v("รายการผู้ใช้งาน")]),_c('span',{staticClass:"weight-700 fontsize-h5 ml-2"},[_vm._v("จำนวน "+_vm._s(_vm._f("toCommas")(_vm.total))+" รายการ")])]),_c('div',{},[_c('button',{staticClass:"btn btn-submit",attrs:{"type":"button"},on:{"click":_vm.addNewUser}},[_c('font-awesome-icon',{staticClass:"font-white mr-2",attrs:{"icon":['fas', 'plus-circle']}}),_vm._v(" เพิ่มผู้ใช้งาน ")],1)])]),_c('b-table',{staticClass:"table-custom box-shadow",attrs:{"id":"my-table-user","hover":"","borderless":"","show-empty":"","items":_vm.items,"fields":_vm.fields,"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center fontsize-h4 my-4"},[_vm._v("ไม่พบข้อมูล")])]},proxy:true},{key:"head()",fn:function(data){return [_c('div',{class:data.field.align === 'left'
              ? 'text-left'
              : data.field.align === 'right'
              ? 'text-right'
              : 'text-center'},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"head(index)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"cell(index)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s((_vm.currentPage - 1) * _vm.perPage + (data.index + 1))+" ")])]}},{key:"cell()",fn:function(data){return [(data.item.alignRow !== undefined)?_c('div',{class:data.item.alignRow === 'left'
              ? 'text-left'
              : data.item.alignRow === 'right'
              ? 'text-right'
              : 'text-center'},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',{class:data.field.align === 'left'
              ? 'text-left'
              : data.field.align === 'right'
              ? 'text-right'
              : 'text-center'},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(canEdit)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-edit",attrs:{"type":"button","disabled":!data.item.canEdit},on:{"click":function($event){return _vm.editRow(data.item.userID)}}},[_c('font-awesome-icon',{staticClass:"color-icon-edit fa-lg",attrs:{"icon":['fas', 'edit']}})],1)])]}},{key:"cell(canDelete)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-trash",attrs:{"type":"button","disabled":!data.item.canDelete},on:{"click":function($event){return _vm.deleteRow(data.item.userID)}}},[_c('font-awesome-icon',{staticClass:"color-icon-trash fa-lg",attrs:{"icon":['fas', 'trash-alt']}})],1)])]}}])},[_c('div',{staticClass:"text-center font-blueinfo my-2",attrs:{"slot":"table-busy"},slot:"table-busy"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"pl-2"},[_vm._v("กำลังโหลด...")])],1)]),_c('b-pagination',{staticClass:"pagination-custom mt-3",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"align":"right","aria-controls":"my-table-user"},on:{"change":function($event){return _vm.changePage($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-outline-submit",attrs:{"type":"submit"},on:{"click":function($event){return _vm.gotoMenuPage()}}},[_c('iconify',{staticClass:"fontsize-h4 mr-2",attrs:{"icon":"fa-solid:home"}}),_c('span',{staticClass:"align-middle"},[_vm._v("กลับไปยังหน้ารายการ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }